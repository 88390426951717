<template>
    <div class="flex_layout inner">
        <div class="flex_top">
            <div class="query flex">
                <a-space>
                    <div class="query_box">
                        <a-button class="primary btn" @click="update('add', 0)">Add</a-button>
                    </div>
                </a-space>
            </div>
        </div>
        <div class="flex_body">
            <a-spin :spinning="spinning">
               <a-table :dataSource="dataSource" :columns="columns" :pagination="false">
                   <template #action="{ record }">
                       <a-space>
                           <a-button class="primary btn" @click="update('edit', record.class_id, record.class_name)" size="small" type="primary">Edit</a-button>
                           <a-popconfirm
                                title="Are you sure delete this?"
                                ok-text="Yes"
                                cancel-text="No"
                                @confirm="() => remove(record.class_id)"
                            >
                               <a-button danger size="small">Delete</a-button>
                           </a-popconfirm>
                       </a-space>
                   </template>
               </a-table>
            </a-spin>
        </div>
        <a-drawer
            title="Prdocut class"
            placement="bottom"
            height="100%"
            :visible="visible"
            @close="onClose"
            :drawerStyle="{background: '#f9f9f9'}"
            >
               <div class="container">
                   <a-spin :spinning="spinning">
                    <div class="form">
                        <div class="form_title">Product class</div>
                        <a-form :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-item label="Class name">
                                <a-input v-model:value="formData.class_name" placeholder="Class name" />
                            </a-form-item>
                            <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                                <a-button type="primary" @click="updateProductClassHandler">Submit</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                   </a-spin>
               </div>
        </a-drawer>
    </div>
</template>
<script>
const columns = [
    {
        title: 'Class id',
        dataIndex: 'class_id',
        key: 'class_id',
    },
    {
        title: 'Class name',
        dataIndex: 'class_name',
        key: 'class_name',
    },
    {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
    },
]
import { message } from 'ant-design-vue'
import { getProductClassList, updateProductClass, removeProductClass } from '@/apis/productClass'
export default {
    name: 'Product',
    data(){
        return {
            dataSource: [],
            columns,
            visible: false,
            formData: {
                class_name: '',
                class_id: 0,
                action_type: 'add'
            },
            labelCol: { span: 6 },
            wrapperCol: { span: 12 },
            spinning: false,
        }
    },
    methods: {
        update(actionType, class_id, class_name){
            this.formData.action_type = actionType
            if(actionType == 'add'){
                this.formData.class_name = ''
            }
            if(actionType == 'edit'){
                this.formData.class_name = class_name
            }
            this.formData.class_id = class_id
            this.visible = true
        },
        async updateProductClassHandler(){
            if(this.formData.class_name == '') return message.error('Please input class name')
            this.spinning = true
            const res = await updateProductClass(this.formData)
            this.spinning = false
            if(res){
                this.visible = false
                this.getProductClassListHandler()
            } 
        },
        async remove(class_id){
            this.spinning = true
            const res = await removeProductClass({class_id})
            this.spinning = false
            if(res){
                this.getProductClassListHandler()
            } 
        },
        onClose(){
            this.visible = false
        },
        async getProductClassListHandler(){
            this.spinning = true
            const res = await getProductClassList()
            this.spinning = false
            if(res){
                this.dataSource = res.list  
            }
        }
    },
    created(){
        this.getProductClassListHandler()
    }
}
</script>
<style lang="stylus" scoped>
</style>